import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'currentInterval',
    'newInterval',
    'currentDiscountAmount',
    'newDiscountAmount',
  ];

  update() {
    // NOTE: We'll need to update this to be more dynamic in the future
    const selectors = ['beef', 'chicken', 'turkey'];

    this.newIntervalTarget.textContent = this.currentIntervalTarget.value;
    if (this.hasNewDiscountAmountTarget) {
      this.newDiscountAmountTarget.textContent =
        this.currentDiscountAmountTarget.textContent;
    }

    selectors.forEach((selector) => {
      const originElement = document.querySelector(`.${selector}.current`);
      const element = document.querySelector(`.${selector}.new`);
      if (
        originElement.querySelector('.account-recipe-checkbox input').checked
      ) {
        const price = originElement.querySelector('.current-price').textContent;
        const skuDropdown = originElement.querySelector('.current-weight');
        const weight = skuDropdown.options[skuDropdown.selectedIndex].text;
        const quantity = originElement.querySelector(
          '.account-recipe-quantity'
        ).value;
        const measureWord = quantity == 1 ? 'box' : 'boxes';
        element.querySelector('.new-price').textContent = price;
        element.querySelector('.new-weight').textContent = weight;
        element.querySelector('.new-quantity').textContent = `${
          originElement.querySelector('.account-recipe-quantity').value
        } ${measureWord}`;
        element.style.display = 'block';
      } else {
        element.style.display = 'none';
      }
    });
  }
}
